function setCookieConsentStatus(status) {
  const days = status == "allow" ? 365 : 31;
  const expiry = new Date(new Date().getTime()+1000*60*60*24*days).toGMTString();
  document.cookie = "HM__cookieConsentStatus=" + status + "; expires=" + expiry + "; path=/";
  $(".cookie-banner").fadeOut("fast");

  // TODO: Trigger an event to GTM so we can track this first hit?
}

$("#cookieBannerRejectButton").on("click", function() {
  setCookieConsentStatus("reject");
});

$("#cookieBannerAcceptButton").on("click", function() {
  setCookieConsentStatus("allow");
});
