//-----------------//
// Related content //
//-----------------//

// We need to do some observing of the card image height
// to allow for the precise vertical alignment with the
// grain background. I tried lots of approaches without
// JS but none were really sensible.

const relatedContentBreakpoint = 640;

$(document).ready(relatedContentLayoutShift);
$(window).resize(relatedContentLayoutShift);

function relatedContentLayoutShift() {
  const relatedContent = $(".related-content");

  if (relatedContent) {
    const windowWidth = $(window).width();
    const firstRelatedCard = $(".related-content .card:nth-child(1)");
    const firstRelatedCardImageHeight = firstRelatedCard.children(".card__image").height();
    $(".related-content__grain").css("height", firstRelatedCardImageHeight);

    if (windowWidth >= relatedContentBreakpoint) {
      $(".related-content .card").css("margin-top", (firstRelatedCardImageHeight * -1) + "px");
    } else {
      $(".related-content .card").css("margin-top", "");
      firstRelatedCard.css("margin-top", (firstRelatedCardImageHeight * -1) + "px");
    }
  }
}
