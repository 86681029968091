$("#shareButton").on("click", function () {
  var $this = $(this);
  var parent = $this.parent();
  var menu = parent.find("ul");

  if (parent.hasClass("share--expanded")) {
    $this.removeAttr("aria-expanded");
    menu.fadeOut("fast");
  } else {
    $this.attr("aria-expanded", "true");
    menu.fadeIn("fast");
  }

  parent.toggleClass("share--expanded");
});

$("#copyLink, .social-link--copy").on("click", function(e) {
  e.preventDefault();

  var $this = $(this);
  var label = $this.children(".social-link__label");
  var url = $this.attr("href");

  navigator.clipboard.writeText(url);

  label.text("Link copied!");
  setTimeout(function () {
    label.text("Copy link");
  }, 3000);
});
