function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const randomInt = randomIntFromInterval(1, 5);

$(".page-header").addClass("page-header--shape-config-" + randomInt);
$(".site-header").addClass("site-header--shape-config-" + randomInt);

if ($(".page-header .shape").length) {
  gsap.registerPlugin(ScrollTrigger);

  gsap.to(".page-header .shape__circle", {
    yPercent: -65,
    scrollTrigger: {
      trigger: ".site-header",
      start: "top top",
      end: window.innerHeight,
      scrub: true,
    },
  });

  gsap.to(".page-header .shape__rect", {
    yPercent: -75,
    rotate: "+=35deg",
    scrollTrigger: {
      trigger: ".site-header",
      start: "top top",
      end: window.innerHeight,
      scrub: true,
    },
  });
}
