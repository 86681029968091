const navBreakpoint = 860;
const nav = $("#mainNav");
const mobileNavButton = $("#mobileNavButton");

//------------------------//
// Mobile expand-collapse //
//------------------------//

function collapseMobileMenu() {
  nav.fadeOut("fast");
  mobileNavButton.children(".nav-icon--active").fadeOut("fast");
  mobileNavButton.removeAttr("aria-expanded");
  mobileNavButton.children("span").text("Menu");
}

function expandMobileMenu() {
  nav.fadeIn("fast");
  mobileNavButton.children(".nav-icon--active").fadeIn("fast");
  mobileNavButton.attr("aria-expanded", "true");
  mobileNavButton.children("span").text("Close");
}

mobileNavButton.on("click", function () {
  // Collapse
  if (mobileNavButton.attr("aria-expanded")) {
    collapseMobileMenu();
  }

  // Expand
  else {
    expandMobileMenu();
  }
});

//-------------------------//
// Submenu expand-collapse //
//-------------------------//

function getViewportWidth() {
  return window.innerWidth;
}

function expandSubNav(topLevelLink) {
  topLevelLink.addClass("main-nav__top-level-a--active");
  topLevelLink.attr("aria-expanded", "true");
  topLevelLink.siblings().fadeIn({
    duration: "fast",
    start: function () {
      $(this).css({ display: "flex" });
    },
  });
  if ($(window).width() >= navBreakpoint) {
    topLevelLink.children(".active-nav-item-triangle").fadeIn("fast");
  }
}

function collapseOtherSubNavs(topLevelLink) {
  const otherTopLevelLinks = topLevelLink
    .parent()
    .siblings()
    .children(".main-nav__top-level-a[aria-haspopup]");
  otherTopLevelLinks.each(function () {
    collapseSubNav($(this));
  });
}

function otherSubNavsAreOpen() {
  return $(".main-nav__top-level-a--active").length;
}

function collapseSubNav(topLevelLink) {
  topLevelLink.removeClass("main-nav__top-level-a--active");
  topLevelLink.attr("aria-expanded", "false");
  topLevelLink.siblings().fadeOut("fast");
  topLevelLink.children(".active-nav-item-triangle").fadeOut("fast");
}

$(".main-nav__top-level-a[aria-haspopup]").on("click", function () {
  const $this = $(this);
  const isExpanded = $this.attr("aria-expanded");

  if (isExpanded == "false") {
    if (getViewportWidth() >= navBreakpoint && otherSubNavsAreOpen()) {
      collapseOtherSubNavs($this);
      setTimeout(function () {
        expandSubNav($this);
      }, 200);
    } else {
      collapseOtherSubNavs($this);
      expandSubNav($this);
    }
  } else {
    collapseSubNav($this);
  }
});

//-----------------------------//
// Resizing across breakpoints //
//-----------------------------//

var prevWindowWidth = 0;

$(document).ready(function () {
  prevWindowWidth = $(window).width();
});

function resizedWindow() {
  var currentWindowWidth = $(window).width();
  var desktopToMobile = prevWindowWidth > navBreakpoint && currentWindowWidth <= navBreakpoint;
  var mobileToDesktop = prevWindowWidth < navBreakpoint && currentWindowWidth >= navBreakpoint;

  prevWindowWidth = currentWindowWidth;

  // console.log("previous:", prevWindowWidth);
  // console.log("current:", currentWindowWidth);

  if (desktopToMobile) {
    // console.log("desktop to mobile");
    nav.css("display", "none");
  }

  // Mobile to desktop
  else if (mobileToDesktop) {
    // console.log("mobile to desktop");
    nav.css("display", "block");
  }

  // Breaking across either way
  if (desktopToMobile || mobileToDesktop) {
    mobileNavButton.removeAttr("aria-expanded");
    mobileNavButton.children("span").text("Menu");
    $(".main-nav__top-level-a[aria-haspopup]").each(function () {
      const $this = $(this);
      $this.removeClass("main-nav__top-level-a--active");
      $this.attr("aria-expanded", "false");
    });
    $(".main-nav__second-level").css("display", "none");
    $(".active-nav-item-triangle").css("display", "none");
  }
}

var doit;
$(window).resize(function () {
  clearTimeout(doit);
  doit = setTimeout(resizedWindow, 100);
});

///

$("#goBackLink").on("click", function() {
  history.back();
});
