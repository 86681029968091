function accordionToggle(_this, className) {
  var $this = $(_this);
  var parent = $this.parents("." + className);
  var body = parent.find("." + className + "__body");

  if (parent.hasClass(className + "--expanded")) {
    $this.attr("aria-expanded", "false");
    body.slideUp("fast");
  } else {
    $this.attr("aria-expanded", "true");
    body.slideDown("fast");
  }

  parent.toggleClass(className + "--expanded");
}

$(".sprig-component").on("click", ".accordion__trigger", function () {
  accordionToggle(this, "accordion");
});

$(".accordion__trigger").on("click", function () {
  if (!$(this).parents(".sprig-component").length) {
    accordionToggle(this, "accordion");
  }
});

$(".person__trigger").on("click", function () {
  accordionToggle(this, "person");
});

$(".accordion-with-logo__header").on("click", function () {
  accordionToggle(this, "accordion-with-logo");
});
